.hero-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 38px;
    height: auto;
    padding: 50px 0;
}
.hero-img-wrapper {
    width: 541px;
    height: 375px;
}
.hero-img-wrapper img {
    width: 100%;
}

        /* Texto */

.hero-info-wrapper {
    padding: 70px 0 0 80px;
    color: #212121;;
}
.hero-info-wrapper h2 {
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 20px;
}
.hero-info-wrapper ul li{
    margin-left: 18px;
    padding-right: 20px;
    width: 42ch;
    line-height: 28px;
}
.hero-info-wrapper p {
    width: 50ch;
    margin-bottom: 20px;
    padding: 0 20px 0 0;
}
@media screen and (max-width: 1136px) {
    .hero-wrapper {
        flex-wrap: wrap;

        gap: 18px;
    }
    .hero-info-wrapper {
        padding: 70px 0px 0px 0px;
        color: #212121;
    }
}
@media screen and (max-width: 1012px) {

    .hero-info-wrapper {
        width: 541px;
        padding: 20px 0px 0px 0px;
    }
}
@media screen and (max-width: 549px) {

    .hero-info-wrapper {
        width: 100%;
        padding-left: 10px;
    }
    .hero-img-wrapper {
        width: 100%;
        height: auto;
    }
    .hero-info-wrapper p {
        width: auto;
    }
    .hero-info-wrapper ul li{
        width: auto;
    }
}