.cliente-espacamento-habitue {
    margin-bottom: -80px;
}
.cliente-espacamento-habitue-bottom {
    padding: 30px 0;
}
@media screen and (max-width: 559px) {
    
    .cliente-espacamento-habitue-bottom {
        padding: 0;
    }
}