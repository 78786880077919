.form-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}
.form-escopo {
    height: 717px;
    width: 670px;
    padding: 80px 10px 0 10px;
}
.form-radio-box {
    display: flex;
    justify-content: center;
    color: #0C2340;
    gap: 5px;
    margin-bottom: 30px;
}
.form-radio-box p {
    cursor: default;
}
.form-describe-inputs {
    font-size: 17px;
    margin-bottom: 10px;
}
#form-inputTwo {
    margin-left: 10px;
}
.form-wrap-inputs {
    width: 100%;
} 
.form-wrap-inputs input {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px 10px;
    border-radius: 15px;
    font-size: 18px;
}
.form-wrap-inputs textarea {
    width: 100%;
    height: 100px;
    resize: none;
    border-radius: 10px;
    padding: 10px;
    font-size: 18px;
    margin-bottom: 20px;
    border: solid 2px #0C2340;
}
.form-btn {
    background-color: #2D548D;
    color: #ffffff;
    padding: 10px 65px;
    font-size: 18px;
    cursor: pointer;
}
.form-btn:hover {
    background-color: #154175;
    transition: 0.3s;
}



/* Detalhes decorativos Formulario  */

.form-wrapper-right {
    width: 65%;
}
.form-text-wrap-decoration {
    width: 35%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 6rem;
}
.form-text-wrap-decoration img {
    height: 470px;
    margin-right: 30px;
    margin-bottom: 80px;
}

@media screen and (max-width: 1139px ) {
    
    .form-wrapper-right {
        width: 75%;
    }
    .form-text-wrap-decoration {
        width: 25%;
    } 
}
@media screen and (max-width: 897px) {
    
    .form-wrapper-right {
        width: 85%;
    }
    .form-text-wrap-decoration {
        width: 15%;
    }
}

@media screen and (max-width: 788px) {
    .form-escopo {
        width: 570px;
    }
}
@media screen and (max-width: 678px) {
    .form-escopo {
        width: 470px;
    }
    .form-text-wrap-decoration img {
        margin-right: 2px;
    }
}
@media screen and (max-width: 557px) {
    .form-escopo {
        width: 100%;
    }
    .form-text-wrap-decoration {
        display: none;
    }
}