.est-wrapper {
    width: 100%;
    display: flex;
    margin-bottom: 100px;
}
.est-wrapper-left {
    width: 30%;
    height: auto;
}
.est-absolut-image-wrap {
    position: absolute;
    margin-top: 40px;
    margin-left: 220px;
}
.est-absolut-image-wrap img {
    width: 100%;
}
.est-info-wrapper-right {
    width: 70%;
    height: 469px;
    color: #ffffff;
    background-color: #10213A;
}
.est-info-wrap-text {
    padding: 190px 0 0 450px;
}
.est-info-wrap-text h2 {
    font-weight: bold;
    font-size: 2rem;
}
.est-info-wrap-text p {
    width: 50ch;
}
@media screen and (max-width: 1294px ) {
    
    .est-info-wrapper-right {
        width: 80%;
    }
    .est-wrapper-left {
        width: 20%;
    }
    .est-absolut-image-wrap {
        position: absolute;
        margin-top: 40px;
        margin-left: 50px;
    }
}
@media screen and (max-width: 1137px ) {
    
    .est-info-wrap-text p {
        width: auto;
        padding-right: 3%;
    }
}
@media screen and (max-width: 998px ) {
    
    .est-wrapper {
        flex-wrap: wrap;
        background-color: #10213A;

    }
    .est-info-wrapper-right {
        width: 100%;
        height: auto;
        padding-bottom: 50px;
    }
    .est-info-wrap-text {
        padding: 30px 0 0 0;
    }
    .est-info-wrap-text h2 {
        font-size: 2rem;
        text-align: center;
        margin-bottom: 20px;
    }
    .est-alinhamento-texto {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .est-info-wrap-text p {
        width: 50ch;
        text-align: center;
    }
    .est-wrapper-left {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .est-absolut-image-wrap {
        position: static;
        margin-top: 40;
        margin-left: 0;
        width: 550px;
    }
}
