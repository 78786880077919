.evento-espacamento-topo {
    margin-bottom: -120px;
}
.evento-titulo-sessao-equipamentos {
    width: 100%;
    padding: 30px 0 50px 0;
    text-align: center;    
}
.evento-titulo-sessao-equipamentos h2 {
    font-size: 3.4rem;
    font-weight: bold;
    text-transform: uppercase;
}
.eventos-txt-equip {
    text-align: center;
    padding: 40px 20px;
}

@media screen and (max-width: 590px ) {
    
    .evento-titulo-sessao-equipamentos h2 {
        font-size: 2.5rem;
    }   
}