.chevron-down {
  margin-left: 0px;
  margin-right: 0px !important;
  align-self: center;
}

@media (min-width: 320px) and (max-width: 1000px) {
  .chevron-down {
    display: none;
  }
}
