.Restaurante-wrapper {
    width: 100%;
    margin-bottom: 190px;
}
.Restaurante-wrapper img{
    width: 100%;
}
.rest-info-wrappper {
    margin-top: -270px;
}
.rest-info-wrappper h2 {
    font-size: 3.4rem;
    color: #ffffff;
    text-align: center;
    margin-bottom: 10px;
}
.rest-text-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
}
.rest-text-wrapper p {
    width:  50ch;
    color: #ffffff;
}

@media screen and (max-width: 1006px ) {

    .Restaurante-wrapper {
        margin-top: 50px;
    }
    .rest-info-wrappper {
        margin-top: -220px;
    }
}
@media screen and (max-width: 870px ) {

    .rest-info-wrappper {
        margin-top: -180px;
    }
}
@media screen and (max-width: 720px ) {

    .rest-info-wrappper {
        margin-top: -180px;
    }
    .rest-info-wrappper h2 {
        font-size: 3rem;
    }
    .Restaurante-wrapper img{
        width: 100%;
        height: 250px;
    }
}
@media screen and (max-width: 473px ) {

    .rest-info-wrappper {
        margin-top: -210px;
    }
    .rest-info-wrappper h2 {
        font-size: 2.5rem;
    }
    .rest-text-wrapper p {
        width: auto;
        padding: 0 20px;
    }
}