.footer-container {
    display: flex;
    flex-direction: column;
    background-color: #7f7f7e;
    width: 100%;
}

.footer-item {
    display: flex;
    flex-direction: column;
    margin: 50px;
    justify-content: center;
}

.footer-item-title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
    color: #000;
    text-decoration: none;
}

.footer-item-text {
    font-size: 16px;
    align-self: center;
    margin: 5px 0px;
    text-decoration: none;
    color: #000;
}

.footer-item-icon,
.footer-item-icon-short {
    align-self: center;
    margin-right: 10px;
}

.footer-social-icon {
    height: 30px;
    width: 30px;
    margin: 10px 5px;
}

.footer-social-icon:hover {
    cursor: pointer;
}

.footer-row {
    display: flex;
    flex-direction: row;
}

.double-item {
    display: flex;
    flex-direction: row;
}

.footer-chevron-down {
    display: flex;
    margin-left: 5px;
    align-self: center;
}

.footer-row {
    display: flex;
    flex-direction: row;
    margin-right: auto;
    margin-left: auto;
}

/* botao de redes sociais */

.footer-btn-redes-sociais {
    text-decoration: none;
    color: #000;
}
.footer-btn-redes-sociais:hover {
    transition: 0.3s;
    color: #eee;
}

@media (min-width: 320px) and (max-width: 1000px) {

    .footer-row {
        flex-direction: column;
        margin: 0px;
    }

    .double-item {
        margin-left: auto;
        margin-right: auto;
    }

    .footer-row {
        flex-direction: column;
    }

    .footer-item-icon {
        margin-left: auto;
        margin-right: auto;
    }
}