.habitue-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    padding: 10px 0;
}
.habitue-wrap-left {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: flex-end;
}
.habitue-posi-left {
    padding-top: 30px;
    margin-right: 100px;
}
.habitue-wrap-right {
    width: 50%;
    height: auto;
}
.habitue-titulo-wrap-left h2 {
    font-size: 3.3rem;
}
.habitue-titulo-wrap-left p {
    font-size: 16px;
    width: 48ch;
    margin-bottom: 30px;
}
.habitue-colunas-wrap-text-left {
    display: flex;
}
.habitue-info-coluna-text-left h4 {
    font-size: 18px;
    margin-bottom: 10px;
}
.habitue-info-coluna-text-left p {
    width: 25ch;
}
/* /Image */
.habitue-wrap-image-right {
    width: 538px;
    margin-left: 20px;
}
.habitue-wrap-image-right img {
    width: 100%;
}

@media screen and (max-width: 1141px ) {
    
    .habitue-wrapper {
        flex-wrap: wrap;
    }
    .habitue-posi-left {
        margin-right: 50px;
    }
}
@media screen and (max-width: 1118px ) {
    
    .habitue-wrap-image-right {
        width: 438px;
    }
}
@media screen and (max-width: 1044px ) {
    .habitue-posi-left {
        margin-right: 10px;
    }
    
}
@media screen and (max-width: 952px) {
    
    .habitue-wrap-left {
        width: 100%;
        justify-content: center;
        margin-bottom: 30px;
    }
    .habitue-posi-left {
        padding-top: 0;
        margin-right: 0;
    }
    .habitue-wrap-right {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
    }
    .habitue-wrap-image-right {
        width: 538px;
        margin-left: 0;
    }
}
@media screen and (max-width: 767px) {
    
    .habitue-titulo-wrap-left h2 {
        padding-top: 50px;
        font-size: 2rem;
        margin-bottom: 30px;
    }
}
@media screen and (max-width: 559px) {
    
    .habitue-wrap-image-right {
        width: 100%;
        margin-bottom: -5px;
    }
    .habitue-wrapper {
        padding: 10px 0 0 0;
    }
}

@media screen and (max-width: 473px) {
    
    .habitue-colunas-wrap-text-left {
        flex-wrap: wrap;
    }
    .habitue-posi-left {
        margin-left: 5px;
    }
    .habitue-info-coluna-text-left {
        width: 100%;
        margin: 10px 0;
    }
    .habitue-titulo-wrap-left p {
        font-size: 16px;
        width: auto;
        padding-right: 20px;
        margin-bottom: 30px;
    }
}