.carousel-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.carousel-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-content {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}

.left-arrow,
.right-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  color: #fff;
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.17970938375350143) 0%,
    rgba(0, 0, 0, 0) 70%
  );
}

.left-arrow:hover,
.right-arrow:hover {
  cursor: pointer;
}

.left-arrow {
  left: 24px;
}

.right-arrow {
  right: 24px;
}

.carousel-selector-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 1;
  justify-content: center;
  bottom: 10px;
  margin-left: 45%;
  width: 10%;
  align-items: flex-end;
}

.carousel-selector-empty,
.carousel-selector-fill {
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 5px;
  background-color: #8f8c8c;
  margin: 3px;
}

.carousel-selector-empty:hover,
.carousel-selector-fill:hover {
  cursor: pointer;
}

.carousel-selector-fill {
  background-color: #fff;
  min-width: 15px !important;
  min-height: 15px !important;
  border-radius: 10px !important;
}

@media (min-width: 320px) and (max-width: 650px) {
  .left-arrow,
  .right-arrow {
    display: none;
  }
}
