.whats-wrapper {
    position: fixed;
    display: flex;
    right: 2rem;
    bottom: 2rem;
    z-index: 10;
    padding: 3px 1px;
    border-radius: 10px;
}
.whats-botton-wrap {
    width: 3rem;
    height: 3rem;
    background-color: green;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 2rem;
    border-radius: 2rem;
    text-decoration: none;
    cursor: pointer;
}
.whats-botton-wrap:hover {
    transition: 0.5s;
    color: #ffffff;
    background-color: rgb(33, 194, 33);
}
.whats-options-wrap {
    width: auto;
    height: auto;
    display: flex;
    padding-left: 3px;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    gap: 10px;
}
.whats-options-wrap a {
    text-decoration: none;
    font-weight: 400;
    color: #ffffff;
    background-color: green;
    border-radius: 15px 15px 0 15px;
    padding: 7px 10px;
    font-size: 0.9rem;
    border: solid 1px #4ff765;
    letter-spacing: 0.5px;
}
.whats-options-wrap a:hover {
    transition: 0.3s;
    background-color: rgb(33, 194, 33);
    color: #ffffff;    
}