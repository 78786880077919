



/* quartos Direito*/

.wrapper-quartos-joinville {
    padding: 80px 0 80px 0;
    width: 100%;
    display: flex;
}
.bg-quartoR-joinville {
    width: 40%;
    max-height: 500px;
    background-color: #10213A;
    margin: 30px 0;
}
.info-wrapper-quartoR-joinville {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    margin-top: 40px;
    width: 50%;
    max-height: 433px;
    padding: 30px 50px 50px 110px;
}
.info-wrapper-quartoR-joinville h3 {
    margin-bottom: 10px;
    font-size: 1.8rem;
    color: #212121;
}
.info-wrapper-quartoR-joinville p {
    margin-bottom: 30px;
    font-size: 16px;
    color: #212121;
    width: 55ch;
}
.img-wrapper-quartoR-joinville {
    width:60%;
}
.img-wrapper-quartoR-joinville img {
    width: 100%;
    max-height: 600px;
}
/* Botoes */
.joinville-wrapper-btn-quarto {
    display: flex;
}
.joinville-wrapper-btn-quarto button {
    text-decoration: none;
    padding: 15px 40px;
    font-size: 0.9rem;
    color: #ffffff;
    background-color: #0C2340;
    font-weight: bold;
    cursor: pointer;
    border: none;
}
.joinville-wrapper-btn-quarto button:hover {
    transition: 0.3s;
    background-color: #154175;
    color: #ffffff;
}

/* Icones  */

.joinville-wrapper-icones-quarto {
    margin-bottom: 30px;
    display: flex;
    text-align: center;
    gap: 50px;
}

.joinville-wrapper-icones-quarto i {
    font-size: 3rem;
}
.joinville-wrapper-icones-quarto h4 {
    font-weight: 600;
}



/* Quarto Esquerdo */

.joinville-wrapper-QuartoLeft {
    width: 100%;
    display: flex;
    height: auto;
}
.joinville-wrapper-image-QuartoLeft {
    width: 60%;
}
.joinville-wrapper-image-QuartoLeft img {
    width: 100%;
    max-height: 600px;
}
.joinville-wrapper-info-QuartoLeft {
    width: 40%;
    background-color: #10213A;
    height: 500px;
    margin: 28px 0;
}
.joinville-wrapper-texto-QuartoLeft {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    margin-top: 40px;
    width: 705px;
    height: 413px;
    right: 0%;
    padding: 40px 50px 30px 110px;
}
.joinville-wrapper-botoes-QuartoLeft {
    display: flex;
}
.joinville-wrapper-texto-QuartoLeft h3 {
    margin-bottom: 10px;
    font-size: 1.8rem;
    color: #212121;
}
.joinville-wrapper-texto-QuartoLeft p {
    margin-bottom: 30px;
    font-size: 16px;
    color: #212121;
    width: 55ch;
}
.joinville-wrapper-botoes-QuartoLeft a {
    text-decoration: none;
    padding: 15px 40px;
    font-size: 0.9rem;
    background-color: #0C2340;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
}
.joinville-wrapper-botoes-QuartoLeft a:hover {
    transition: 0.3s;
    background-color: #154175;
    color: #ffffff;
}

/* /// modal quarto Left  */


.quartoleft-wrap-carousel img {
    max-height: 350px;
    object-fit: cover;
    width: 100%;
}
.modal-wrapper-Ql {
    display: flex;
    width: 100%;
    height: auto;
}
.quartoLeft-modal-wrap {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 850px;
    height: 510px;
    background-color:#ffffff;
    border-radius: 5px;
    outline: 0;
    box-shadow: 24;
}
.quartoleft-content-modal {
    padding: 10px 20px;
    width: 50%;
}
.quartoleft-content-modal h3 {
    font-size: 1.2rem;
    margin-bottom: 5px;
}
.quartoleft-content-modal p {
    width: 55ch;
    font-size: 0.8rem;
    border-right: solid 1px #121213;
    padding-right: 10px;
}
.modal-icons-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 200;
    padding-top: 10px;
    width: 50%;
    text-align: center;
}
.modal-icons-wrap i {
    font-size: 1.5rem;
}
.modal-icons-wrap h4 {
    font-size: 0.9rem;
    font-weight: 400;
    width: 15ch;
}
@media screen and (max-width: 1360px ) {
    
    .info-wrapper-quartoR-joinville, .joinville-wrapper-texto-QuartoLeft {
        width: 45%;
        padding: 30px 20px 25px 50px;    
    }
    .joinville-wrapper-icones-quarto {
        margin-bottom: 20px;
    }
    .joinville-wrapper-icones-quarto i {
        font-size: 2.5rem;
    }
    .info-wrapper-quartoR-joinville p {
        margin-bottom: 10px;
    }

    /* quarto left */

    .joinville-wrapper-texto-QuartoLeft p {
        margin-bottom: 10px;
    }

}
@media screen and (max-width: 1272px ) {
    
    .bg-quartoR-joinville, .joinville-wrapper-info-QuartoLeft {
        width: 100%;  
        height: 450px;
        display: flex;
        justify-content: center;
        background-color: #ffffff;
        margin: -10px;
    }
    .wrapper-quartos-joinville {
        padding: 40px 0;
        justify-content: center;
        flex-wrap: wrap-reverse;
    }
    .joinville-wrapper-QuartoLeft {
        justify-content: center;
        flex-wrap: wrap;
    }
    .info-wrapper-quartoR-joinville, .joinville-wrapper-texto-QuartoLeft {
        position: static;
        margin-top: 0;
        width: 60%;
        max-height: 380px;
        padding: 30px 20px 20px 0px;
    }
}
@media screen and (max-width: 900px) {
    
    .quartoLeft-modal-wrap {
        width: 650px;
        height: 410px;
    }
    .quartoleft-wrap-carousel img {
        max-height: 250px;
    }
    .quartoleft-content-modal p {
        width: 40ch;
    }
    .modal-icons-wrap i {
        font-size: 1.3rem;
    }
    .modal-icons-wrap h4 {
        font-size: 0.8rem;
    }
}
@media screen and (max-width: 832px ) {
    
    .joinville-wrapper-texto-QuartoLeft p, .info-wrapper-quartoR-joinville p  {
        width: auto;
    }
    .joinville-wrapper-icones-quarto {
        gap: 20px;
    }
}
@media screen and (max-width: 767px) {
    
    .info-wrapper-quartoR-joinville, .joinville-wrapper-texto-QuartoLeft {
        width: 100%;
        padding: 30px 20px 20px 20px;
    }
    .joinville-wrapper-image-QuartoLeft, .img-wrapper-quartoR-joinville {
        width: 93%;
    }
    .joinville-wrapper-texto-QuartoLeft p, .info-wrapper-quartoR-joinville p  {
        margin-bottom: 30px;
    }
}
@media screen and (max-width: 681px) {
    
    .quartoLeft-modal-wrap {
        width: 80%;
        height: auto;
        padding-bottom: 10px;
    }
    .modal-wrapper-Ql {
        flex-wrap: wrap;
    }
    .modal-icons-wrap {
        width: 100%;
        justify-content: flex-start;
        gap: 10px;
    }
    .quartoleft-content-modal {
        width: 100%;
    }
    .quartoleft-content-modal p {
        border-right: none;
        width: auto;
    }
}
@media screen and (max-width: 420px ) {
    
    .bg-quartoR-joinville, .joinville-wrapper-info-QuartoLeft {
        height: 550px;
        margin-bottom: 30px;
    }
    .info-wrapper-quartoR-joinville, .joinville-wrapper-texto-QuartoLeft {
        height: auto;
    }
    .joinville-wrapper-botoes-QuartoLeft, .joinville-wrapper-btn-quarto {
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 10px;
    }
    .joinville-wrapper-icones-quarto {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .joinville-wrapper-btn-quarto a, .joinville-wrapper-botoes-QuartoLeft a {
        margin-left: 0px;
    } 
}
@media screen and (max-width: 335px ) {
    
    .bg-quartoR-joinville, .joinville-wrapper-info-QuartoLeft {
        height: 590px;
        margin-bottom: 50px;
    }
}