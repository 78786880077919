.btn-book {
  display: flex;
  width: 160px;
  height: 48px;
  background-color: #0c2340;
  color: #fff;
  padding: 10px 16px 10px 16px;
}

.btn-book:hover {
  cursor: pointer;
  transition: 0.3s;
  background-color: #154175;
}

.btn-book-text {
  font-weight: 700;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
}

.header-checkin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #fff;
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  border-radius: 5px;
  transition: all ease 0.3s;
}

.header-container {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #7f7f7e;
  width: 100%;
}

.header-item,
.header-item-mobile {
  margin: 20px;
  font-size: 16px;
  font-weight: 600;
  align-self: center;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: #000;
}

.header-item:hover,
.header-item-mobile:hover {
  transition: 0.2s;
  color: #eee;
}

.header-item-mobile {
  display: none;
}

.header-item-mobile,
.header-item-mobile-option {
  width: 100%;
  margin: 0px;
  padding: 20px;
  padding-left: 30px;
}

.header-item:hover {
  cursor: pointer;
}

.header-item-checkin {
  width: 140px;
  margin: 20px;
  font-size: 16px;
  font-weight: 600;
  align-self: center;
  display: flex;
  flex-direction: row;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #000;
}

.header-item-checkin:hover {
  cursor: pointer;
}

.header-item-icon {
  align-self: center;
  margin: 5px;
}

.header-item-text {
  align-self: center;
  margin-left: 2, 5px;
}

.header-menus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  transition: all 0.3 ease;
}

.header-section {
  display: flex;
  flex-direction: row;
}

.header-logo {
  margin: 20px 0px;
  margin-left: 5%;
}

.row {
  display: flex;
  flex-direction: row;
}

.chevron-down {
  margin-left: 5px;
  margin-left: 10px;
  align-self: center;
}

.double-item {
  display: flex;
  flex-direction: row;
}

.header-row {
  display: flex;
  flex-direction: row;
  margin-right: 5%;
}

.menu-mobile-container {
  display: none;
}

.menu-mobile-bar {
  position: relative;
  width: 35px;
  border-width: 3px;
  border-color: #000;
  border-style: solid;
  transition: all 0.3s ease;
}

.double-margin-mobile {
  margin-top: 0px;
}

.header-item-mobile-option {
  margin: 0px;
  font-size: 16px;
  font-weight: 600;
  align-self: center;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: #000;
  width: 100%;
  /* background-color: #a6a6a6; */
  background-color: #6b6b6b;
  padding-left: 50px;
}

.header-toggle-checkin {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-top: auto;
}

.header-toggle-checkin-text {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.header-toggle-checkin-icon {
  margin-left: auto;
  margin-right: auto;
  width: 30px;
  height: 30px;
}

@media (min-width: 320px) and (max-width: 1000px) {
  .header-item-mobile {
    display: flex;
  }

  .header-item {
    display: none;
  }

  .header-logo {
    display: block;
  }

  .double-item {
    margin-left: auto;
    margin-right: auto;
  }

  .header-row {
    flex-direction: column;
    margin-right: 0px;
  }

  .header-menus {
    flex-direction: column;
    min-height: 50px;
  }

  .menu-mobile-container {
    position: absolute;
    right: 15px;
    top: 21px;
    display: flex;
    flex-direction: column;
    height: 30px;
    width: 35px;
    justify-content: space-between;
  }

  .menu-mobile-container:hover {
    cursor: pointer;
  }

  .header-checkin {
    flex-direction: column;
    margin-bottom: 10px;
    padding: 5px 0px 0px 0px;
  }

  .btn-book {
    width: 145px;
    height: 40px;
  }

  .double-margin-mobile {
    margin-top: 10px;
  }
}

@media (min-width: 320px) and (max-width: 360px) {
  .header-checkin {
    max-width: 325px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    border-radius: 5px;
  }
}
