.endereco-wrapper {
    width: 100%;
    display: flex;
}
.end-mapa-wrapper-left {
    width: 65%;
}
.end-mapa-wrapper-left img {
    width: 100%;
    max-height: 425px;
}

.mapa {
    width: 100%;
    max-height: 425px;
    height: 425px;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
    border: 0;
}

.end-info-wrap-right {
    width: 50%;
    padding: 100px 0 0 120px ;
    color:  #212121;
}
.end-info-wrap-right h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 30px;
}
.end-info-wrap-right ul li {
    list-style: none;
    line-height: 40px;
}
.end-info-icones {
    margin-right: 10px;
    font-size: 13px;
}
.end-info-wrap-right li a {
    text-decoration: none;
    color:  #212121;
}
.end-info-wrap-right li a:hover {
    color:  #154175;
}
.end-btn-wrap-contato {
    margin-top: 20px;
}
.end-btn-wrap-contato a {
    text-decoration: none;
    background-color: #182F52;
    color: #ffffff;
    padding: 15px 35px;
}
.end-btn-wrap-contato a:hover {
    transition: 0.3s;
    background-color: #154175;
    ;
}
.end-icon-btn-contato {
    position: absolute;
    margin-top: 2px;
    margin-left: 3px;
}

@media screen and (max-width: 1155px) {
    
    .endereco-wrapper {
        flex-wrap: wrap;
    }
    .end-mapa-wrapper-left {
        width: 100%;
    }
    .end-info-wrap-right {
        width: 100%;
        padding: 50px 0 50px 30px ;
        color:  #212121;
    }
}
@media screen and (max-width: 385px) {
    
    .end-info-wrap-right {
        padding: 50px 0 50px 10px ;
    }
}