.salaL-wrapper, .salaR-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    padding: 20px 0 70px 0;
}
.salaL-list-wrapper {
    display: flex;
    gap: 20px;
}
.salaL-info-wrapper {
    width: 560px;
    height: auto;
}
.salaR-info-wrapper {
    margin-right: 84px;
}
.salas-img-wrapper, .salaR-img-wrapper {
    width: 550px;
    height: 611px;
    background-color: #10213A;
    padding-top: 89px;
}

    /* Caixas azuis */

.salas-img-wrap-carousel {
    position: absolute;
    right: 164px;
    width: 540px;
    height: 388px;
}
.salaR-carousel-wrap {
    position: absolute;
    left: 164px;
    width: 540px;
    height: 388px;
}
.salaL-textos-wrap, .salaR-texto-wrapper {
    margin-top: 30px;
    color: #212121;
}
.salaR-texto-wrapper {
    margin-top: 90px;
    color: #212121;
}
.salaL-textos-wrap h2 {
    font-size: 3rem;
    margin-bottom: 20px;
}
.salaL-textos-wrap p {
    font-size: 16px;
    padding-right: 20px;
    width: 50ch;
}
.salaL-list-wrapper {
    width: 490px;
    display: flex;
    align-items: flex-end;
    padding: 10px 0;
}
.salaL-list-wrapper ul {
    list-style: none;
}
.salaL-list-wrapper ul li {
    padding: 15px 10px;
    border-bottom: solid 0.5px rgb(72, 108, 224);
    border-left: solid 0.5px rgb(72, 108, 224);
    font-size: 16px;
}
.salaL-nome-salas {
    text-align: center;
}

/* Botão agendamento  */

.salasL-wrapper-btn {
    margin-top: 70px;
}
.salasL-wrapper-btn a {
    text-decoration: none;
    background-color: #0C2340;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    padding: 15px 35px;
}
.salasL-wrapper-btn a:hover {
    transition: 0.3s;
    background-color: #154175;
}
    /* SALAS
    DE 
    REUNIÃO 
    LADO DIREITO */

.salaR-texto-wrapper h2 {
    font-size: 3rem;
    margin-bottom: 20px;
}
.salaR-texto-wrapper p {
    font-size: 16px;
    padding-right: 20px;
    width: 55ch;
}
.salaR-list-wrapper {
    width: auto;
    display: flex;
    align-items: flex-end;
    padding: 10px 0;
}
.salaR-list-wrapper ul {
    list-style: none;
}
.salaR-list-wrapper ul li {
    padding: 15px 25px;
    border-bottom: solid 0.5px rgb(72, 108, 224);
    border-left: solid 0.5px rgb(72, 108, 224);
    font-size: 18px;
}
.salaR-nome-salas {
    text-align: center;
}


/* Responsivo Sessoes */

.salaL-wrapper-left, .salaL-wrapper-right {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: flex-end;
}
.salaR-wrapper-left {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: flex-start;
}
.salaR-wrapper-right {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-left: 120px;
}


/* Ajustes Responsivo  */

@media screen and (max-width: 1220px ) {
    
    .salaL-wrapper {
        justify-content: center;
        flex-wrap: wrap;
        gap: 30px;
    }
    .salaR-wrapper {
        justify-content: center;
        flex-wrap: wrap-reverse;
        gap: 30px;
    }
    .salaL-wrapper-left, .salaR-wrapper-right {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-start;
        margin: 0 10% 30px 10%;
    }
    .salaL-wrapper-right, .salaR-wrapper-left {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
    }
    .salaL-list-wrapper,  .salaR-list-wrapper {
        margin-left: 10%;
    }
    .salas-img-wrapper, .salaR-img-wrapper {
        width: 100%;
        margin: 0 10%;
        height: auto;
        padding: 0;
        background-color: #10213A;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .salas-img-wrap-carousel, .salaR-carousel-wrap {
        position: static;
        right: 0;
        width: 540px;
        height: 388px;
    }
}

@media screen and (max-width: 649px ) {
    
    .salaL-wrapper-left, .salaR-wrapper-right {
        margin: 0 3% 30px 3%;
    }
    .salaR-texto-wrapper p {
        width: auto;
    }
    .salaR-texto-wrapper {
        margin-top: 0;
    }
    .salas-img-wrapper, .salaR-img-wrapper {
        margin: 0;
    }
    .salas-img-wrap-carousel, .salaR-carousel-wrap {
        width: 100%;
    }
    .salas-img-wrap-carousel, .salaR-carousel-wrap {
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 539px ) {

    .salaL-info-wrapper {
        width: auto;
        height: auto;
    }
    .salaL-list-wrapper {
        margin-left: 0;
        width: 100%;
    }
    .salaL-textos-wrap h2, .salaR-texto-wrapper h2 {
        margin-left: 5px;
    }
    .salaL-textos-wrap p, .salaR-texto-wrapper p {
        width: auto;
        margin-bottom: 20px;
        margin-left: 5px;
    }
}

@media screen and (max-width: 445px ) {
    
    .salaL-textos-wrap h2, .salaR-texto-wrapper h2 {
        font-size: 2.2rem;
        margin-bottom: 10px;
    }
    .salaL-list-wrapper ul li, .salaR-list-wrapper ul li {
        padding: 10px 10px;
        font-size: 14px;
    }
}
@media screen and (max-width: 409px ) {
    
    .salaL-wrapper-left, .salaR-wrapper-right {
        margin: 0 0 30px 0;
    }
    .salaL-list-wrapper ul li, .salaR-list-wrapper ul li {
        font-size: 12px;
    }
    .salasL-wrapper-btn {
        margin-left: 6px;
    }
    .salasL-wrapper-btn a {
        font-size: 16px;
    }
}
@media screen and (max-width: 337px ) {
    
    .salaL-list-wrapper ul li, .salaR-list-wrapper ul li {
        font-size: 10px;
    }
    .salasL-wrapper-btn a {
        font-size: 14px;
    }
}