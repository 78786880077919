.espacamento-icones-joinville {
    margin-top: 10px;
}
.icones-wrapper-joinville {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px 0;
    gap: 30px 5px;
}
.espacamento-section-icones {
    margin-bottom: 10px;
}
.icones-wrapper-joinville i {
    font-size: 3.5rem;
}
.icones-wrapper-joinville h4 {
    text-align: center;
    padding: 0 10px;
    font-weight: 400;
    color: #0C2340;
}

@media screen and (max-width: 767px) {
    
    .icones-wrapper-joinville {
        padding: 5px 10px;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
}