.breakCoffe-wrapper {
    width: 100%;
    height: 408px;
    display: flex;
    background-size: cover;
    justify-content: center;
    background-position: center;
    background-attachment: fixed;
    background-image: url(../../assets/CoffeBreak.webp);
}
.breakCoffe-info-wrapper {
    background-color: #182f52ce;
    text-align: center;
    color: #ffffff;
    height: 100%;
    width: 898px;
}
.breakCoffe-info-wrapper h2 {
    text-transform: uppercase;
    margin-bottom: 30px;
    margin-top: 90px;
    font-size: 3rem;
}
.breakCoffe-info-wrapper p {
    padding: 0 150px;
    font-size: 18px;
}

@media screen and (max-width: 700px ) {
    
    .breakCoffe-wrapper {
        height: auto;
    }
    .breakCoffe-info-wrapper {
        width: 100%;
        text-align: center;
        padding-bottom: 30px;
    }
    .breakCoffe-info-wrapper p {
        padding: 20px;
    }
    .breakCoffe-info-wrapper h2 {
        margin-top: 60px;
        margin-bottom: 10px;
        font-size: 2.5rem;
    }
}