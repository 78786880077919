.carrousel-img-container, .carrousel-img-container img{
    position: relative;
    transition: all .2s ease;
    aspect-ratio: 5 / 2;
}
.carroussel-item {
    width: 100%;
    position: relative;
}
.carroussel-overlay-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.75);
    height: 100px;
    width: 300px;
    bottom: 70px;
    padding-left: 20px;
    right: 0px;
}

.carroussel-overlay-text {
    font-size: 18px;
    font-weight: bold;
    align-self: center;
}

.d-none-mobile{
    display: none;
}

@media (min-width: 320px) and (max-width: 1000px) {

    .carroussel-overlay-container {
        width: 200px;
        bottom: 30px;
        height: 70px;
    }
    .carrousel-img-container img{
        object-fit: cover;
    }
    .carrousel-img-container img, .carrousel-img-container {
        aspect-ratio: 4 / 3;
    }
    .d-none-mobile{
        display:block;
    }
    .d-none-web{
        display: none;
    }
}