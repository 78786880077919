.hotel-item-joinville,
.hotel-item-lages {
    display: flex;
    flex-direction: column;
    width: 50%;
    max-width: 50%;
    height: 540px;
    background-position: center top;
    background-size: cover;
}

.hotel-item-joinville {
    background-image: url('../../assets/home_joinville.webp');
}

.hotel-item-lages {
    background-image: url('../../assets/home_lages.webp');
}

.hotel-item-title,
.hotel-item-text {
    color: #fff;
    font-size: 18px;
    margin: 2.5px;
    margin-left: auto;
    margin-right: auto;
}

.hotel-item-title {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 15px;
    text-decoration: none;
}
.hotel-item-title:hover {
    color: #eee;
    transition: 0.3s;
}

.chevron-right-more {
    color: #fff;
    align-self: center;
    width: 20px;
    height: 28px;

}
@media (min-width: 320px) and (max-width: 1000px) {

    .hotels-container {
        flex-direction: column;
    }

    .hotel-item-joinville,
    .hotel-item-lages {
        max-width: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 380px ) {

    .hotel-item-title,
    .hotel-item-text {
        color: #fff;
        font-size: 16px;
        margin: 2.5px;
    }

}