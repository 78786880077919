.carroussel-container {
  width: 100%;
  margin-top: 180px;
}

.carroussel-row {
  width: 6 * 100vh;
  display: flex;
  flex-direction: row;
  transition: all ease 0.5s;
}

.smart-container {
  display: flex;
  flex-direction: row;
  padding: 5% 0%;
}
.smart-item {
  margin-left: auto;
  max-width: 40%;
  margin-right: 5%;
  margin-top: auto;
  margin-bottom: auto;
}

.smart-item-title {
  font-size: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.smart-item-text {
  font-size: 18px;
}

.benefits-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #7f7f7e;
}

.benefits-item {
  display: flex;
  margin: 30px;
  /* background-color: #f00; */
  max-width: 140px;
  flex-direction: column;
  padding: 10px;
}

.benefits-item-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  margin-right: auto;
}

.benefits-item-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.benefits-item-text {
  font-size: 14px;
  margin: 5px 0px;
}

.double-item {
  display: flex;
  flex-direction: row;
}

.hotels-container {
  display: flex;
  flex-direction: row;
}
.smart-item-image {
  max-width: 40%;
  width: 40%;
}

@media (min-width: 320px) and (max-width: 1000px) {
  .carroussel-container {
    margin-top: 160px !important;
  }
  .smart-item-title {
    font-size: 25px;
  }

  .smart-item-text {
    font-size: 14px;
  }

  .benefits-container {
    flex-direction: column;
  }

  .double-item {
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 320px) and (max-width: 650px) {
  .smart-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: none;
    padding: 5% 0 0 0;
    width: 100%;
  }
  .smart-item {
    max-width: 100%;
    width: 100%;
    height: 100%;
    padding-left: 10px;
    margin: 40px 0 80px 0;
  }
  .smart-item-title {
    margin-bottom: 5px;
  }

  .smart-item-text {
    font-size: 18px;
    padding-right: 10px;
  }

  .smart-item-image {
    height: auto;
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    margin: 0;
  }

  .benefits-item-icon {
    width: 40px;
    height: 40px;
  }

  .benefits-item-title {
    font-size: 14px;
  }

  .benefits-item-text {
    font-size: 12px;
  }
}
