.bannertop-espacamento {
    width: 100%;
    height: 35vh;
}
.banner-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}
.banner-titulo {
    display: flex;
    justify-content: center;
    margin-top: -180px;
    margin-bottom: 200px;
    color: #ffffff;
    font-size: 3.4rem;
}
.banner-wrapper-sobre {
    width: 100%;
    display: flex;
    justify-content: center;
}
.banner-wrapper-sobre p {
    width: 72ch;
    padding: 40px 0 60px 0;
    font-size: 18px;
}

@media screen and (max-width: 1170px) {
    
    .banner-wrapper img {
        width: 100%;
    }
    .bannertop-espacamento {
        height: 28vh;
    }
    
}
@media screen and (max-width: 850px ) {
    
    .banner-titulo {
        margin-top: -150px;
    }
}
@media screen and (max-width: 767px ) {
    
    .banner-titulo {
        font-size: 3rem;
        margin-top: -130px;
        margin-bottom: 100px;            
    }
    .banner-wrapper-sobre p {
        padding: 0px 20px 60px 20px;
    }
}
@media screen and (max-width: 655px ) {
    
    .banner-titulo {
        font-size: 2.5rem;
        margin-top: -120px;            
    }
    .banner-wrapper img {
        height: 250px;
        object-fit: cover;
    }
}
@media screen and (max-width: 555px ) {
    
    .banner-titulo {
        font-size: 2rem;
        margin-top: -150px;
        text-align: center;
        margin-bottom: 150px;
        padding: 0 20px;           
    }
    .bannertop-espacamento {
        height: 15vh;
    }
}