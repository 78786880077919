.header-item-label {
  font-size: 12px;
  font-weight: 700;
  margin: 0px 15px;
}

.header-item-input {
  margin: 0px 10px;
  font-size: 16px;
  font-weight: 600;
  align-self: center;
  display: flex;
  flex-direction: row;
}

.header-item-input:hover {
  cursor: pointer;
}

.header-item-icon {
  align-self: center;
  margin: 5px;
}

.header-item-text {
  align-self: center;
  margin-left: 2, 5px;
}

.chevron-down {
  margin-left: auto;
  align-self: center;
}

@media screen and (max-width: 320px) {
  .header-item-text {
    font-size: 13px;
  }
}

@media screen and (min-width: 1024px) {
  .header-item-text {
    font-size: 11px;
  }
}

@media screen and (min-width: 1025px) {
  .header-item-text {
    font-size: 14px;
  }
}
