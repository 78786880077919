.lazer-espacamento-hero {
    margin-bottom: -110px;
}
.lazer-espacamento-carousel {
    width: 100%;
    padding: 50px 0;
}
.Lazer-carrousel01, .Lazer-carrousel02, .Lazer-carrousel03 {
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 65vh;
    color: #ffffff;
}
.Lazer-carrousel01 {
    background-image: url(../../assets/academia01.webp);
}
.Lazer-carrousel02 {
    background-image: url(../../assets/academia02.webp);
}
.Lazer-carrousel03 {
    background-image: url(../../assets/academia03.webp);
}
.lazer-titulo-carrousel {
    margin-top: 180px;
    margin-bottom: 10px;
    font-size: 3.4rem;
    text-align: center;
}
.lazer-texto-carrousel {
    text-align: center;
    padding: 0 20px;
    font-size: 18px;
}

@media screen and (max-width: 685px) {
    
    .lazer-espacamento-carousel {
        padding: 2px 0;
    }
    .lazer-titulo-carrousel {
        font-size: 3rem;
    }
    .lazer-texto-carrousel {
        text-align: center;
        padding: 0 20px;
        font-size: 14px;
    }
}