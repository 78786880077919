.coffe-wrapper {
    width: 100%;
    height: auto;
}
.coffe-content-wrapper {
    width: 1115px;
    height: 469px;
    background-color: #10213A;
    display: flex;
}
.coffe-info-wrapper {
    color: #ffffff;
    padding: 170px 0 0 220px;
}
.coffe-info-wrapper h3 {
    font-size: 2.8rem;
}
.coffe-info-wrapper p {
    width: 48ch;
}
    /* Carrousel  */

.coffe-carousel-wrapper {
    position: absolute;
    right: 6%;
    margin-top: 50px;
    width: 538px;
    height: 385px;
}
.coffe-escopo-carousel {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1286px) {
    
    .coffe-info-wrapper {
        padding: 170px 0 0 40px;
    }
}
@media screen and (max-width: 1118px) {
    
    .coffe-content-wrapper {
        width: 100%;
        justify-content: space-evenly;
    }
    .coffe-carousel-wrapper {
        position: static;
        padding-top: 50px;
        width: 438px;
        height: 315px;
    }
}
@media screen and (max-width: 926px) {
    
    .coffe-content-wrapper {
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
        height: auto;
        padding-bottom: 50px;
    }
    .coffe-info-wrapper {
        padding: 50px 0 0 40px;
    }
    .coffe-carousel-wrapper {
        padding-top: 0;
    }
}
@media screen and (max-width: 526px) {
    
    .coffe-content-wrapper {
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
        height: auto;
        padding-bottom: 80px;
    }
    .coffe-info-wrapper {
        padding: 80px 0 0 10px;
    }
    .coffe-carousel-wrapper {
        width: 100%;
    }
    .coffe-info-wrapper p {
        width: auto;
        padding-right: 20px;
    }
}
@media screen and (max-width: 535px) {
    
    .coffe-content-wrapper {
        padding-bottom: 10px;
    }
}