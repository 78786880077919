.joinville-cfdmanha-wrapper {
    width: 100%;
    height: auto;
}
.joinville-cfdmanha-text-wrapper {
    text-align: center;
}

.joinville-cfdmanha-text-wrapper h2 {
    font-weight: bold;
    font-size: 3.4rem;
    margin-bottom: 10px;
}
.cfd-txt-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
}
.cfd-txt-wrapper p {
    max-width: 49ch;
    font-size: 18px;
    margin-bottom: 70px;
}
/* Imagem */

.cfdmanha-image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0 30px 0;
}

.cfd-coloracao-image {
    margin-top: -350px;
    width: 100%;
    background-color: #10213A;
    height: 280px;
    margin-bottom: 180px;
}


@media screen and (max-width: 1143px ) {
    .cfdmanha-image-wrapper {
        width: 100%;
    }
    .cfdmanha-image-wrapper img {
        width: 100%;
    }
    .cfd-coloracao-image {
        display: none;
    }
}
@media screen and (max-width: 537px ) {
    
    .joinville-cfdmanha-wrapper {
        padding: 50px 0 0 0;
    }
    .joinville-cfdmanha-text-wrapper h2 {
        font-size: 3rem;
    }
    .cfd-txt-wrapper p {
        width: auto;
        padding: 0 20px;
        margin-bottom: 20px;
    }
}
@media screen and (max-width: 428px ) {
    
    .joinville-cfdmanha-wrapper {
        padding: 110px 0 0 0;
    }
    .joinville-cfdmanha-text-wrapper h2 {
        font-size: 2.5rem;
    }
    .cfd-txt-wrapper p {
        width: auto;
        padding: 0 20px;
        margin-bottom: 20px;
    }
}
@media screen and (max-width: 325px ) {
    
    .joinville-cfdmanha-wrapper {
        padding: 150px 0 0 0;
    }
    .joinville-cfdmanha-text-wrapper h2 {
        font-size: 2rem;
    }

}